<template>
  <div id="fullpage" :style="'background-color:' + color">
    <a id="image_to_download"></a>
    <!-- DESKTOP HEADER -->
    <a-header
      id="main_header"
      :title="title"
      :main_filters="main_filters"
      :image_source="image_source"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <template v-slot:options>
        <slot name="header_options"></slot>
      </template>
    </a-header>

    <!-- MOBILE HEADER -->
    <a-header-mobile
      v-else
      :image_source="image_source"
      :main_filters="main_filters"
    >
      <template v-slot:options>
        <slot name="header_options"></slot>
      </template>
    </a-header-mobile>

    <!-- PAGE CONTENT -->
    <v-main id="page" :style="'background-color:' + color + ' !important;'">
      <v-img
        :height="imageHeight + 'px'"
        width="100%"
        :position="'top center'"
        class="top-img"
        :src="image_source"
      ></v-img>
      <div class="px-1 px-lg-4 pb-6 pb-md-0 mb-md-0" :style="contentStyle">
        <slot></slot>
      </div>
    </v-main>
    <!-- <v-btn
      v-scroll="onScroll"
      v-show="show_scroll_top"
      fab
      dark
      fixed
      bottom
      right
      :small="$vuetify.breakpoint.xsOnly"
      color="#435eec"
      @click="toTop"
      class="mb-12 mb-sm-0 data-html2canvas-ignore"
      id="scroll-top-button"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import aHeader from "@/components/aHeader";
import aHeaderMobile from "@/components/aHeaderMobile";
import * as Utils from "@/utils.js";
import { state } from "@/store/modules/segmentschannels";

export default {
  props: {
    image_source: String,
    image_height: Number,
    mobile_image_height: Number,
    main_filters: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "#fdfdfd"
    }
  },
  components: {
    aHeader,
    aHeaderMobile
  },
  data: () => ({
    show_scroll_top: false,
    subscribed_modules: [],
    loading_screenshot_button: false,
    watch_renew: 0
  }),
  computed: {
    imageHeight() {
      // Ha de canviar quan estem en mobile + menu_extended + main_filters
      var height = this.image_height ? this.image_height : 300;
      var device_height =
        this.$vuetify.breakpoint.xsOnly && this.mobile_image_height
          ? this.mobile_image_height
          : height;
      device_height +=
        this.menu_extended && this.main_filters
          ? this.$vuetify.breakpoint.xsOnly
            ? 75
            : 160
          : 0;
      return device_height;
    },
    contentStyle() {
      var offset =
        this.imageHeight - (this.$vuetify.breakpoint.xsOnly ? 46 : 64);
      offset -=
        this.menu_extended && this.main_filters
          ? this.$vuetify.breakpoint.xsOnly
            ? 75
            : 160
          : 0;
      return "width:100%; margin-top: -" + offset.toString() + "px;";
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      }
    },
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set(value) {
        this.$store.dispatch("setMini", value);
      }
    },
    menu_extended: {
      get() {
        return this.$store.state.menu_extended;
      },
      set(value) {
        this.$store.dispatch("setMenuExtended", value);
      }
    },
    // WATCHERS
    bi_report_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.vs_filter_selected_option,
        this.vs_hotel_selected,
        this.otb_option,
        this.selected_year,
        this.selected_month,
        this.watch_renew
      ].join("_");
    },
    bi_basic_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.otb_option,
        this.selected_year,
        this.selected_month,
        this.watch_renew
      ].join("_");
    },
    highlight_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.highlights_extraction_id,
        this.watch_renew
      ].join("_");
    },
    rate_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.extraction_id,
        this.vs_id,
        this.pax,
        this.childs,
        this.regime,
        this.cancel_policy,
        this.genius,
        this.mobile_device,
        this.watch_renew
      ].join("_");
    },
    rate_detail_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.extraction_id,
        this.vs_id,
        this.pax,
        this.childs,
        this.regime,
        this.cancel_policy,
        this.rate_date_to_compare,
        this.genius,
        this.mobile_device,
        this.watch_renew
      ].join("_");
    },
    rate_basic_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.extraction_id,
        this.watch_renew
      ].join("_");
    },
    parity_basic_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.extraction_id,
        this.pax,
        this.watch_renew
      ].join("_");
    },
    user_hotel_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.watch_renew
      ].join("_");
    },
    channelmanager_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.channelmanager_page ? this.channelmanager_page : 0,
        this.watch_renew
      ].join("_");
    },
    specialrates_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.specialrates_page ? this.specialrates_page : 0,
        this.watch_renew
      ].join("_");
    },
    offers_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.offers_page ? this.offers_page : 0,
        this.watch_renew
      ].join("_");
    },
    rms_report_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.watch_renew
      ].join("_");
    },
    rms_report_months_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.dashboard_pickup_months_page
          ? this.dashboard_pickup_months_page
          : 0,
        this.watch_renew
      ].join("_");
    },
    rms_month_report_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_pickup_month,
        this.current_pickup_year,
        this.watch_renew
      ].join("_");
    },
    rms_all_kpis_daily_spot_date_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.strategy_from ? this.strategy_from : 0,
        this.strategy_to ? this.strategy_to : 0,
        this.strategy_spot_date ? this.strategy_spot_date : 0,
        this.watch_renew
      ];
    },
    rms_all_kpis_daily_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.strategy_from ? this.strategy_from : 0,
        this.strategy_to ? this.strategy_to : 0,
        this.watch_renew
      ];
    },
    rms_availability_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.availability_from ? this.availability_from : 0,
        this.availability_to ? this.availability_to : 0,
        this.watch_renew
      ];
    },
    rms_hotels_kpis_period_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_hotels_kpis_period &&
        this.current_hotels_kpis_period.length > 0
          ? this.current_hotels_kpis_period.join("_")
          : 0,
        this.watch_renew
      ].join("_");
    },
    rms_year_sales_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.year_sales_commission,
        this.year_sales_ly_year,
        this.watch_renew
      ].join("_");
    },
    rms_dashboard_year_sales_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.year_sales_commission,
        // this.dashboard_first_day,
        this.dashboard_last_day,
        this.watch_renew
      ].join("_");
    },
    rms_dashboard_year_view_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.year_sales_commission,
        this.dashboard_year,
        this.dashboard_year_full_view ? 1 : 0,
        this.watch_renew
      ].join("_");
    },
    rms_segmentation_report_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.segmentation_report_commission,
        this.segmentation_report_breakdown,
        this.segmentation_report_from,
        this.segmentation_report_to,
        this.segmentation_report_dimension,
        this.segmentation_report_kpi_graph,
        this.watch_renew
      ].join("_");
    },
    rms_revenue_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.revenue_from,
        this.revenue_to,
        this.revenue_vs,
        this.watch_renew
      ].join("_");
    },
    rms_year_sales_year_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.year_sales_year,
        this.watch_renew
      ].join("_");
    },
    rms_booking_pace_weekly_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.strategy_from,
        this.strategy_to,
        this.watch_renew
      ].join("_");
    },
    revenue_dates_calendar_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_user ? this.current_user.id : 0,
        this.revenue_dates_calendar_dimension
          ? this.revenue_dates_calendar_dimension
          : 0,
        this.watch_renew
      ].join("_");
    },
    loading_screenshot: {
      get() {
        return this.$store.state.loading_screenshot;
      },
      set(value) {
        this.$store.dispatch("setLoadingScreenshot", value);
      }
    },
    share_image_src: {
      get() {
        return this.$store.state.share_image_src;
      },
      set(value) {
        this.$store.dispatch("setShareImageSrc", value);
      }
    },
    share_image_width: {
      get() {
        return this.$store.state.share_image_width;
      },
      set(value) {
        this.$store.dispatch("setShareImageWidth", value);
      }
    },
    share_image_height: {
      get() {
        return this.$store.state.share_image_height;
      },
      set(value) {
        this.$store.dispatch("setShareImageHeight", value);
      }
    },
    share_content: {
      get() {
        return this.$store.state.share_content;
      },
      set(value) {
        this.$store.dispatch("setShareContent", value);
      }
    },
    channelmanager_page() {
      return this.$store.state.channelmanager.page;
    },
    specialrates_page() {
      return this.$store.state.specialrates.page;
    },
    offers_page() {
      return this.$store.state.offers.page;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    }),
    ...mapState({
      vs_filter_selected_option: "vs_filter_selected_option",
      vs_hotel_selected: "vs_hotel_selected",
      selected_year: "selected_year",
      selected_month: "selected_month",
      otb_option: "otb_option",
      extraction_id: "extraction_id",
      vs_id: "vs_id",
      pax: "pax",
      childs: "childs",
      regime: "regime",
      genius: "genius",
      mobile_device: "mobile_device",
      cancel_policy: "cancel_policy",
      rate_date_to_compare: "rate_date_to_compare",
      highlights_extraction_id: "highlights_extraction_id",
      current_pickup_month: state => state.rms.current_pickup_month,
      current_pickup_year: state => state.rms.current_pickup_year,
      dashboard_pickup_months_page: state =>
        state.rms.dashboard_pickup_months_page,
      strategy_from: state => state.rms.strategy_from,
      strategy_to: state => state.rms.strategy_to,
      strategy_spot_date: state => state.rms.strategy_spot_date,
      availability_from: state => state.rms.availability_from,
      availability_to: state => state.rms.availability_to,
      current_hotels_kpis_period: state => state.rms.current_hotels_kpis_period,
      reload_subscribed_modules: state => state.reload_subscribed_modules,
      year_sales_commission: state => state.rms.year_sales_commission,
      year_sales_ly_year: state => state.rms.year_sales_ly_year,
      year_sales_year: state => state.rms.year_sales_year,
      dashboard_year_full_view: state => state.rms.dashboard_year_full_view,
      dashboard_year: state => state.rms.dashboard_year,
      dashboard_first_day: state => state.rms.dashboard_first_day,
      dashboard_last_day: state => state.rms.dashboard_last_day,
      segmentation_report_commission: state =>
        state.rms.segmentation_report_commission,
      segmentation_report_breakdown: state =>
        state.rms.segmentation_report_breakdown,
      segmentation_report_from: state => state.rms.segmentation_report_from,
      segmentation_report_to: state => state.rms.segmentation_report_to,
      segmentation_report_dimension: state =>
        state.rms.segmentation_report_dimension,
      segmentation_report_kpi_graph: state =>
        state.rms.segmentation_report_kpi_graph,
      revenue_from: state => state.rms.revenue_from,
      revenue_to: state => state.rms.revenue_to,
      revenue_vs: state => state.rms.revenue_vs,
      revenue_dates_calendar_dimension: state =>
        state.dataimport.revenue_dates_calendar_dimension
    })
  },
  watch: {
    rms_year_sales_year_to_watch() {
      this.fetchData([
        "rms_kpis_year_sales_months",
        "rms_kpis_year_sales_quarters",
        "rms_kpis_year_sales_year"
      ]);
    },
    rms_all_kpis_daily_spot_date_to_watch() {
      this.fetchData([
        "rms_all_kpis_daily_dimension",
        "rms_all_kpis_period_dimension"
      ]);
    },
    rms_all_kpis_daily_to_watch() {
      this.fetchData([
        "rms_all_kpis_daily",
        "rms_all_kpis_daily_compset",
        "rms_all_kpis_daily_pickup",
        "rms_pvp_mlos_changes_daily",
        "rms_strategy_range_kpis",
        "rms_strategy_range_kpis_table",
        "rms_strategy_range_kpis_pickups_table",
        "segmentschannels_segments",
        "segmentschannels_segment_channels"
      ]);
    },
    rms_availability_to_watch() {
      this.fetchData(["rms_roomtype_availability"]);
    },
    rms_report_to_watch() {
      this.fetchData([
        "rms_kpis_monthly_graph",
        "rms_segments_period_graph",
        "rms_annual_kpis",
        "rms_ytd_annual_kpis"
        // "rms_dashboard_kpis_pickup_month_graph",
        // "rms_dashboard_booking_pace_month_graphs",
        // "rms_dashboard_month_kpis",
        // "rate_dashboard_rate_resume_period"
      ]);
    },
    rms_report_months_to_watch() {
      this.fetchData([
        "rms_dashboard_kpis_pickup_month_graph",
        "rms_dashboard_booking_pace_month_graphs",
        "rms_dashboard_month_kpis",
        "rate_dashboard_rate_resume_period"
      ]);
    },
    rms_month_report_to_watch() {
      this.fetchData([
        "rms_kpis_month_graph",
        "rms_segments_pickup_month_graph",
        "rms_kpis_pickup_month_graph1",
        "rms_kpis_pickup_month_graph2",
        "rms_month_kpis",
        "rms_ytd_month_kpis",
        "rms_booking_pace"
      ]);
    },
    bi_report_to_watch() {
      this.fetchData([
        "bi_report",
        "bi_daily_report",
        "bi_reputation_answered_by_time"
      ]);
    },
    bi_basic_to_watch() {
      this.fetchData(["bi_reputation_reviews"]);
    },
    highlight_to_watch() {
      this.fetchData(["highlight_list"]);
    },
    rate_to_watch() {
      this.fetchData(["rate_daily_rates", "rate_daily_availability"]);
    },
    rate_basic_to_watch() {
      this.fetchData(["rate_available_dates", "parity_available_dates"]);
    },
    parity_basic_to_watch() {
      this.fetchData(["parity_links"]);
    },
    rate_detail_to_watch() {
      this.fetchData(["rate_day_rates", "channelmanager_day_data"]);
    },
    user_hotel_to_watch() {
      this.fetchData([
        "rate_available_rate_extractions",
        "parity_webs",
        "parity_booking_basic",
        "parity_channels",
        "channelmanager_table_data",
        "channelmanager_page",
        "roomtype_types_mapped",
        "roomtype_types_available",
        "dataimport_revenue_dates",
        "dataimport_revenue_dates_calendar",
        "dataimport_revenue_last_imports",
        "dataimport_revenue_daily_columns",
        "dataimport_revenue_reservation_columns"
      ]);
    },
    channelmanager_to_watch() {
      this.fetchData(["channelmanager_table_data"]);
    },
    specialrates_to_watch() {
      this.fetchData(["specialrates_table_data"]);
    },
    offers_to_watch() {
      this.fetchData(["offers_table_data"]);
    },
    rms_hotels_kpis_period_to_watch() {
      this.fetchData([
        "rms_hotels_kpis_period",
        "rms_hotels_kpis_period_graph"
      ]);
    },
    rms_year_sales_to_watch() {
      this.fetchData([
        "rms_year_sales_monthly_graph",
        "rms_year_sales_period_graph",
        "rms_year_sales_monthly_grown_graph",
        "rms_year_sales_table",
        "rms_year_sales_period_table"
      ]);
    },
    rms_dashboard_year_sales_to_watch() {
      this.fetchData(["rms_dashboard_year_sales_monthly_graph"]);
    },
    rms_dashboard_year_view_to_watch() {
      this.fetchData(["rms_dashboard_annual_kpis"]);
    },
    rms_segmentation_report_to_watch() {
      this.fetchData([
        "rms_segmentation_report_graph",
        "rms_segmentation_report_table",
        "segmentschannels_segments",
        "segmentschannels_segment_channels",
        "roomtype_types_mapped"
      ]);
    },
    rms_revenue_to_watch() {
      this.fetchData([
        "rms_revenue_summary_table",
        "rms_revenue_channels_table",
        "rms_revenue_servicetype_data",
        "rms_revenue_range_kpis",
        "rms_revenue_days_to_reservation_graph",
        "rms_revenue_days_to_cancellation_graph",
        "segmentschannels_segment_channels"
      ]);
    },
    rms_booking_pace_weekly_to_watch() {
      this.fetchData(["rms_booking_pace_weekly"]);
    },
    revenue_dates_calendar_to_watch() {
      this.fetchData(["dataimport_revenue_dates_calendar"]);
    },
    // Modules only depending on the hotel
    current_hotel() {
      this.fetchData(["rate_competitors", "highlight_info"]);
    },
    reload_subscribed_modules() {
      this.subscribedModules();
      this.refreshAllWatch();
    }
  },
  methods: {
    takeScreenshot() {
      this.loading_screenshot_button = true;
      Utils.hackSvg();
      var vm = this;
      setTimeout(function() {
        vm.loading_screenshot = true;
        vm.share_content = true;
        var page = document.getElementById("page").parentElement;
        window.scrollTo(0, 0);
        vm.$html2canvas(page, {
          // allowTaint: true //you can add this property,
        }).then(canvas => {
          // vm.loading_screenshot;
          vm.loading_screenshot_button = false;
          vm.share_image_src = canvas.toDataURL();
          vm.share_image_width = page.offsetWidth;
          vm.share_image_height = page.offsetHeight;
        });
      }, 500);
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.show_scroll_top = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getSubscribedModulesFromChilds(el) {
      let subscribed_modules = [];
      if (el.$refs) {
        for (let child in el.$refs) {
          let my_subscribed_modules = [];
          if (el.$refs[child] === undefined) {
            continue;
          }
          if (
            el.$refs[child].subscribed_modules &&
            el.$refs[child].subscribed_modules.length > 0
          ) {
            my_subscribed_modules = Object.values(
              el.$refs[child].subscribed_modules
            );
          }
          subscribed_modules = [
            ...subscribed_modules,
            ...my_subscribed_modules,
            ...this.getSubscribedModulesFromChilds(el.$refs[child])
          ];
        }
      }
      return subscribed_modules;
    },
    subscribedModules() {
      let subscribed_modules = this.getSubscribedModulesFromChilds(
        this.$parent
      );

      this.subscribed_modules = subscribed_modules.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    },
    refreshAllWatch() {
      this.watch_renew++;
    },
    fetchData(modules) {
      this.subscribed_modules
        .filter(module => modules.indexOf(module) >= 0)
        .forEach(module => {
          this.$store.dispatch(this.getFetchFromModule(module));
        });
    },
    getFetchFromModule(module) {
      return ("fetch-" + module.replaceAll("_", "-")).replace(
        /-([a-z])/g,
        function(g) {
          return g[1].toUpperCase();
        }
      );
    }
  },
  mounted() {
    this.subscribedModules();
    this.fetchData(this.subscribed_modules);
  }
};
</script>
<style>
.v-application,
body,
html,
.v-application .title,
.v-application .body-1,
.v-application .body-2,
.v-application .display-1,
.v-application .display-2,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .headline,
.v-application .caption,
.v-application .overline {
  letter-spacing: 0.5px !important;
}
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: 600 !important;
}
.v-tabs,
.v-btn {
  letter-spacing: 0.5px !important;
}
#scroll-top-button {
  z-index: 10;
  bottom: 90px;
  right: 20px;
  width: 60px;
  height: 60px;
}
#page {
  padding-top: 0px !important;
  /* background: #fdfdfd; */
}
</style>
